import React from 'react'
import {withPrefix} from 'gatsby'
import Layout from '../components/layouts/layout'
import HeadMeta from '../components/layouts/headmeta'
import ProgressBar from '../components/layouts/progressbar'
import { changeProgressBarOnScroll } from '../components/utils/index'

export default class PressAndMediaPage extends React.Component {
  componentDidMount() {
    window.addEventListener('scroll', function() {
      changeProgressBarOnScroll()
    });
    window.analytics.page('Static', 'Press & Media')
  }

  render() {
    const metadata = {
      title: "Press & Media | Advanx Health",
      description: "",
      image: "https://www.advanxhealth.com/img/share/blog-index.png",
      url: "https://www.advanxhealth.com/press-and-media"
    }

    return (
      <Layout>
        <div className="index">
          <HeadMeta metadata={ metadata } />
          <ProgressBar />
          <section className="hero bg-primary-blue-light" style={{height: '40vh'}}>
            <div className="container">
                <div className="text-center">
                  <h1>Press & Media</h1>
                </div>
            </div>
          </section>
          <section class="container">
            <div className="row">
              <div className="col-md-4 mt-3">
                <div className="card">
                  <div className="card-body">
                    <img className="card-img mb-2" src="https://www.dnaweekly.com/wp-content/themes/comparison/assets/img/logo.png.webp?1" style={{width: '50%'}} alt="DNAweekly"/>
                    <img className="card-img" src="https://advanxweb.s3.ap-southeast-1.amazonaws.com/media/DNAWeeklyFeature.png" alt="Optimize Your Diet And Lifestyle With Advanx Health"/>
                    <p className="small mt-3 mb-1">24th July 2021</p>
                    <h4>Optimize Your Diet And Lifestyle With Advanx Health Combined Testing And Analysis</h4>
                    <a href="https://www.dnaweekly.com/blog/advanx-health-interview/" target="_blank" rel="noopener noreferrer">Read more</a>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mt-3">
                <div className="card">
                  <div className="card-body">
                    <img className="card-img" src={withPrefix('img/media/vulcanpost.png')} style={{width: '50%'}} alt="Vulcanpost"/>
                    <img className="card-img" src="https://cdn01.vulcanpost.com/wp-uploads/2019/06/DNA-Kit-Test-Advanx-Health-03.jpg" alt="Advanx Health test results"/>
                    <p className="small mt-3 mb-1">12th Jun 2019</p>
                    <h4>We Spat Into A Tube 5 Weeks Ago, Here Are The Results</h4>
                    <a href="https://vulcanpost.com/665846/advanx-health-dna-kit-test-review/" target="_blank" rel="noopener noreferrer">Read more</a>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mt-3">
                <div className="card">
                  <div className="card-body">
                    <img className="card-img" src={withPrefix('img/media/malaysiasme.png')} style={{width: '50%'}} alt="Malaysia SME"/>
                    <img className="card-img" src="https://kunming.cn/news/upload/resources/image/2019/05/31/142836_670x670.jpeg" alt="Kunming"/>
                    <p className="small mt-3 mb-1">31st May 2019</p>
                    <h4>"One Belt One Road" 2019 Kunming Innovation Summit Overseas Talent Entrepreneurship conference (OTEC)</h4>
                    <a href="https://kunming.cn/news/c/2019-05-31/12655898.shtml" target="_blank" rel="noopener noreferrer">Read more</a>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mt-3">
                <div className="card">
                  <div className="card-body">
                    <img className="card-img" src={withPrefix('img/media/malaysiasme.png')} style={{width: '50%'}} alt="Malaysia SME"/>
                    <img className="card-img" src={withPrefix('img/media/pillars-nextgen.png')} alt="Pillars Next Gen"/>
                    <p className="small mt-3 mb-1">11th May 2019</p>
                    <h4>It's In Your DNA: Advanx-ing Your Health With Yong Wei Shian And Chew Yen Ping</h4>
                    <a href="https://files.advanxhealth.com/PillarsNextGen-AdvanxHealth.pdf" target="_blank" rel="noopener noreferrer">Read more</a>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mt-3">
                <div className="card">
                  <div className="card-body">
                    <img className="card-img" src={withPrefix('img/media/hustlr.png')} style={{width: '50%'}} alt="Hustlr"/>
                    <img className="card-img" src='https://www.hustlr.com/wp-content/uploads/2019/05/Interview-With-AdvanX-Health.jpg' alt="Interview with Advanx Health"/>
                    <p className="small mt-3 mb-1">9th May 2019</p>
                    <h4>Interview With Advanx Health – DNA Testing and Personalized Health Reports</h4>
                    <a href="https://www.hustlr.com/interview-with-advanx-health-dna-testing-and-personalized-health-reports/" target="_blank" rel="noopener noreferrer">Read more</a>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mt-3">
                <div className="card">
                  <div className="card-body">
                    <img className="card-img" src={withPrefix('img/media/UKM-UMBI.png')} style={{width: '50%'}} alt="UKM UMBI"/>
                    <img className="card-img" src={withPrefix('img/media/CEO-talk.jpg')} alt="CEO Talk"/>
                    <p className="small mt-3 mb-1">3rd May 2019</p>
                    <h4>CEO TALK @UMBI Series 1/2019: Unleashing The Power of Entrepreneurship in Science</h4>
                    <a href="http://www.ukm.my/umbi/news/ceo-talk-umbi-series-1-2019-unleashing-the-power-of-entrepreneurship-in-science/" target="_blank" rel="noopener noreferrer">Read more</a>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mt-3">
                <div className="card">
                  <div className="card-body">
                    <img className="card-img" src={withPrefix('img/media/purelyb.jpg')} style={{width: '50%'}} alt="PurelyB"/>
                    <img className="card-img" src="https://www.purelyb.com/images/IMG_20190321_175822.jpg" alt="Advanx Health DNA Test"/>
                    <p className="small mt-3 mb-1">27th Mar 2019</p>
                    <h4>We Tried The Advanx Health DNA Test—Here’s How It Works</h4>
                    <a href="https://www.purelyb.com/be-nourished/weight-management/we-tried-the-advanx-dna-test-here-s-how-it-works" target="_blank" rel="noopener noreferrer">Read more</a>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mt-3">
                <div className="card">
                  <div className="card-body">
                    <img className="card-img" src={withPrefix('img/media/purelyb.jpg')} style={{width: '50%'}} alt="PurelyB"/>
                    <img className="card-img" src="https://www.purelyb.com/images/easyblog_shared/bigstock-Young-Woman-Eating-Tasty-Asian-273612415_20190225-052522_1.jpg" alt="Food based on your DNA"/>
                    <p className="small mt-3 mb-1">26th Feb 2019</p>
                    <h4>Finding The Best & Worst Foods For You Based On Your DNA—Is It Possible?</h4>
                    <a href="https://www.purelyb.com/be-nourished/weight-management/finding-the-best-worst-foods-for-you-based-on-your-dna-is-it-possible" target="_blank" rel="noopener noreferrer">Read more</a>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mt-3">
                <div className="card">
                  <div className="card-body">
                    <img className="card-img" src={withPrefix('img/media/vulcanpost.png')} style={{width: '50%'}} alt="Vulcapost"/>
                    <img className="card-img" src="https://cdn01.vulcanpost.com/wp-uploads/2019/02/AdvanxHealth_Feature.png" alt="Advanx Health DNA healthcare"/>
                    <p className="small mt-3 mb-1">19th Feb 2019</p>
                    <h4>No One Wants Your Spit—Except This M'sian Health Startup That'll Research Your DNA Secrets</h4>
                    <a href="https://vulcanpost.com/657026/advanx-health-dna-healthcare-malaysia/" target="_blank" rel="noopener noreferrer">Read more</a>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mt-3">
                <div className="card">
                  <div className="card-body">
                    <img className="card-img" src={withPrefix('img/media/nextacademy.png')} style={{width: '50%'}} alt="Next Academy"/>
                    <img className="card-img" src="https://uploads-ssl.webflow.com/5ce16c2f7c5e2f36c000584e/5cff2a64e4d7e2c4f70d48cc_ubisana-blog-header-2.jpeg" alt="Wei Shian & Ping"/>
                    <p className="small mt-3 mb-1">16th May 2018</p>
                    <h4>Founders and Tech Skills: Wei Shian and Yen Ping From Advanx Health</h4>
                    <a href="https://www.nextacademy.com/blog/founders-and-tech-skills-advanx-health" target="_blank" rel="noopener noreferrer">Read more</a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}
